import { Box, Button, Flex, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Spinner, Text, } from '@chakra-ui/react';
import { faLink, faPaperclip, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { GET_ALL_USERS, GET_PROJECTS, GET_TASKS } from '../../../apis/constants.api';
import { getMyProjectsAPI } from '../../../apis/project.api';
import { addNewTask } from '../../../apis/task.api';
import { getPlatformUserDataAPI } from '../../../apis/users.api';
import { AppContext } from '../../../contexts/AppContext';
import { SlateInputField } from '../../RichTextBox/SlateInputField';
import { PriorityOptions, StatusOptions } from '../Contants';
import { createSchema } from '../form.schema';

export default function CreateTaskDialog(props) {
  const { isOpen, onClose, isLink = true, onChange } = props;

  const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE;
  const [page, setPage] = useState(1);
  const [stateApp] = useContext(AppContext);

  const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: {
      title: '',
      description: "",
      priority: PriorityOptions[0],
      status: StatusOptions[0],
      assignee: null,
      project: null,
      dueDate: "",
      reporter: { label: stateApp?.user?.name, value: stateApp?.user?._id }
    },
    resolver: yupResolver(createSchema),
  });
  const { data: project } = useQuery({
    queryKey: [GET_PROJECTS, {
      skip: PAGE_SIZE * (page - 1),
      limit: PAGE_SIZE
    }],
    queryFn: getMyProjectsAPI,
  });
  const { data: users } = useQuery({
    queryKey: [GET_ALL_USERS, {
      skip: PAGE_SIZE * (page - 1),
      limit: PAGE_SIZE
    }],
    queryFn: getPlatformUserDataAPI,
  });

  const projectList = project?.data?.data || [];
  const usersList = users?.data?.data || [];

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(addNewTask, {
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: [GET_TASKS] });
      reset();
      onClose();
    },
  });

  const UserOptions = usersList.filter((e) => e.role === "Student" || stateApp.user?._id === e?._id).map((e) => ({ label: e.name, value: e?._id }));
  const ProjectOptions = projectList.map((e) => ({ label: e.name, value: e._id }));

  const onSubmit = (data) => {
    const payload = {
      title: data?.title,
      description: data?.description,
      priority: data?.priority?.value,
      status: data?.status?.value,
      assignee: data?.assignee?.value,
      project: data?.project?.value,
      dueDate: data?.dueDate,
      reporter: data?.reporter?.value,
      plainDescription: data?.plainDescription
    };

    mutate(payload);
  };

  useEffect(() => {
    reset();
  }, [isOpen]);

  const assignToMe = () => {
    if (stateApp?.user?._id)
      setValue('assignee', { label: stateApp?.user?.name, value: stateApp?.user?._id });
  };

  const initialValue = [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ];

  return (
    <>
      <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} isCentered>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalOverlay />
          <ModalContent minWidth={{ base: "", md: "750px", lg: "900px", xl: "1100px" }}>
            <ModalBody p="0" >
              <Flex flexWrap={{ base: "wrap", md: "nowrap" }}>
                <Box width="100%" p="25px" borderRight="1px solid #E5E5E5">
                  <Flex gap="10px" justifyContent="space-between" alignItems="center">

                    <Text fontSize="fs.18" fontWeight="fw.500" >{"Create Task"}</Text>
                    <Flex justifyContent="end" alignItems="center" gap="20px">
                      <Flex justifyContent="center" cursor="pointer" _hover={{ bgColor: "#F7F7F7" }} onClick={onClose} alignItems="center" width="30px" height="30px" border="1px solid #868B944D" borderRadius="6px">
                        <FontAwesomeIcon color='rgba(134, 139, 148, 1)' icon={faXmark} />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box mt="15px">
                    <Text fontSize={{ base: "fs.12", md: "fs.14", lg: "fs.16" }} fontWeight="fw.500">Title</Text>
                    <Controller
                      name="title"
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState }) =>
                        <>
                          <Input {...field} placeholder="Enter your title" height="50px" _hover={{ borderColor: "#713EDD" }}
                            errorBorderColor="red.500"
                            isInvalid={errors?.title?.message || fieldState?.['title']?.message}
                            background="#F7FAFC" mt="8px" _focusVisible={{ borderColor: "#713EDD" }} />
                          {(errors?.title?.message || fieldState?.['title']?.message)
                            && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.title?.message || fieldState?.['title']?.message}</Text>}
                        </>
                      }
                    />
                  </Box>
                  <Box mt="15px" >
                    <Text fontSize="fs.16" fontWeight="fw.500" mb="8px">Project</Text>
                    <Controller
                      name="project"
                      control={control}
                      render={({ field, fieldState }) =>
                        <>
                          <ReactSelect
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={field.value}
                            isSearchable={true}
                            options={ProjectOptions}
                            onChange={(value) => field.onChange(value)}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                colors: {
                                  ...provided.colors,
                                  // primary25: 'hotpink',
                                  primary: '#713EDD',
                                },
                                height: "50px",
                                borderRadius: "8px",
                                borderColor: errors?.assignee?.message ? 'red' : provided.borderColor,
                                '&:hover': {
                                  borderColor: errors?.assignee?.message ? 'red' : "#713EDD",
                                },
                                "&:focus-within": {
                                  borderColor: "#713EDD",
                                  boxShadow: "none",
                                }
                              }),
                            }}
                          />
                          {(errors?.project?.message || fieldState?.['project']?.message)
                            && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.project?.message || fieldState?.['project']?.message}</Text>}
                        </>

                      }
                    />
                  </Box>
                  <Box mt="15px" >
                    <Text fontSize="fs.16" fontWeight="fw.500" mb="8px">Status</Text>
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) =>
                        <ReactSelect
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={field.value}
                          isSearchable={true}
                          options={StatusOptions}
                          onChange={(value) => field.onChange(value)}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              height: '50px',
                              borderRadius: "8px",
                              borderColor: errors?.assignee?.message ? 'red' : provided.borderColor,
                              '&:hover': {
                                borderColor: errors?.assignee?.message ? 'red' : "#713EDD",
                              },
                              "&:focus-within": {
                                borderColor: "#713EDD",
                                boxShadow: "none",
                              }
                            }),
                          }}
                        />
                      }
                    />
                  </Box>

                  <Box mt="15px">
                    <Flex mb="10px" justifyContent="space-between">
                      <Text fontSize="fs.16" fontWeight="fw.500">Description</Text>
                      {/* {isLink && <Flex gap="20px">
                        <FontAwesomeIcon cursor="pointer" color='#202B3C' icon={faPaperclip} />
                        <FontAwesomeIcon cursor="pointer" color='#202B3C' icon={faLink} />
                      </Flex>
                      } */}
                    </Flex>

                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) =>
                        <SlateInputField
                          placeholder="Enter Description"
                          initValue={initialValue}
                          textContainerStyle={{ minHeight: 100, margin: 10, padding: "0px 13px" }}
                          onChange={field.onChange}
                          setValue={setValue}
                          name={"plainDescription"}
                        />
                      }
                    />

                  </Box>

                  <Box mt="15px">
                    <Text fontSize="fs.16" fontWeight="fw.500" mb="8px">Assignee</Text>
                    <Controller
                      name="assignee"
                      control={control}
                      render={({ field, fieldState }) => {
                        return (
                          <>
                            <ReactSelect
                              className="basic-single"
                              classNamePrefix="select"
                              value={field.value}
                              isSearchable={true}
                              options={UserOptions}
                              onChange={(value) => field.onChange(value)}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  height: '50px',
                                  borderRadius: "8px",
                                  borderColor: errors?.assignee?.message ? 'red' : provided.borderColor,
                                  '&:hover': {
                                    borderColor: errors?.assignee?.message ? 'red' : "#713EDD",
                                  },
                                  "&:focus-within": {
                                    borderColor: "#713EDD",
                                    boxShadow: "none",
                                  }
                                }),
                              }}
                            />
                            {(errors?.assignee?.message || fieldState?.['assignee']?.message)
                              && <Text size={'small'} fontSize={'sm'} mt={1} color="red.500">{errors?.assignee?.message || fieldState?.['assignee']?.message}</Text>}

                          </>
                        );
                      }
                      }

                    />
                    <Text fontSize="fs.14" fontWeight="fw.500" mb="8px" mt="6px" ml="4px" color="#4732b8" cursor={"pointer"} onClick={() => assignToMe()}>Assign to me</Text>
                  </Box>
                  <Box mt="15px" >
                    <Text fontSize="fs.16" fontWeight="fw.500" mb="8px">Reporter</Text>
                    <Controller
                      name="reporter"
                      control={control}
                      render={({ field }) =>
                        < ReactSelect
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled
                          options={[{ label: stateApp?.user?.name, value: stateApp?.user?._id }]}
                          defaultValue={{ label: stateApp?.user?.name, value: stateApp?.user?._id }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              height: '50px',
                              borderRadius: "8px",
                              borderColor: errors?.assignee?.message ? 'red' : provided.borderColor,
                              '&:hover': {
                                borderColor: errors?.assignee?.message ? 'red' : "#713EDD",
                              },
                              "&:focus-within": {
                                borderColor: "#713EDD",
                                boxShadow: "none",
                              }
                            }),
                          }}
                        />
                      }
                    />
                  </Box>

                  <Box mt="15px" >
                    <Text fontSize="fs.16" fontWeight="fw.500" mb="8px">Priority</Text>
                    <Controller
                      name="priority"
                      control={control}
                      render={({ field }) =>
                        <ReactSelect
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={field.value}
                          isSearchable={true}
                          options={PriorityOptions}
                          onChange={(value) => field.onChange(value)}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              height: '50px',
                              borderRadius: "8px",
                              borderColor: errors?.assignee?.message ? 'red' : provided.borderColor,
                              '&:hover': {
                                borderColor: errors?.assignee?.message ? 'red' : "#713EDD",
                              },
                              "&:focus-within": {
                                borderColor: "#713EDD",
                                boxShadow: "none",
                              }
                            }),
                          }}
                        />
                      }
                    />
                  </Box>

                  <Box width="100%" mt={"15px"}>
                    <Text mb="8px" fontSize="fs.16" fontWeight="fw.500">
                      Due Date
                    </Text>

                    <Controller
                      name="dueDate"
                      control={control}
                      render={({ field }) =>
                        <Input
                          {...field}
                          placeholder="Select Date and Time"
                          size="md"
                          type="date"
                          height="50px"
                          bg="#F7FAFC"
                          borderRadius="10px"
                        />
                      }
                    />
                  </Box>
                </Box>


              </Flex>
            </ModalBody>
            <ModalFooter background="#F5F4F6" borderRadius=" 0px 0px 10px 10px">
              <Flex gap="10px">
                <Button background="#EAE9EC" onClick={onClose}>Cancel</Button>
                <Button type="submit" px={{ md: "15px", lg: "30px" }} py="8px"
                  gap={5}
                  background="linear-gradient(134.59deg, #713EDD -1.98%, #A17DEE 111.17%)"
                  _hover={{ background: "linear-gradient(134.59deg, #A17DEE -1.98% ,#713EDD 111.17%)" }} color="white">Save {isLoading && <Spinner />}</Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal >
    </>
  );
}